import * as Sentry from "@sentry/react";
import {
  SENTRY_ENVIRONMENT,
  SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  SENTRY_TRACE_SAMPLE_RATE,
  SENTRY_SAMPLE_RATE,
  SENTRY_TARGET
} from "../constants";

export function initialiseSentry() {
    console.debug(`Sentry config:`, {
        environment: SENTRY_ENVIRONMENT,
        sampleRate: SENTRY_SAMPLE_RATE,
        tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
        replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
        replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
    });

	Sentry.init({
		dsn: SENTRY_TARGET,
		environment: SENTRY_ENVIRONMENT,
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        sampleRate: SENTRY_SAMPLE_RATE,

		// Performance Monitoring
		tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

		// Session Replay
		replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
		replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
	});

    const originalConsoleError = console.error;

    console.error = function (...args) {
	    SENTRY_ENVIRONMENT === "local" ? originalConsoleError(...args) : Sentry.captureException(args[0]);
    };
}
